.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #f5f5f5;
}

.content {
  flex: 1;
}

.main-container {
  margin-top: 4em;
  text-align: left;
}

